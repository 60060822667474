<template>
  <div>
      <b-card class="p-0 m-0">
          <b-container fluid class="p-0 m-0">
              <b-row class="p-0 m-0">
                  <b-col class="p-0 m-0">
                      <b-card class="p-0 mb-0">
                        <b-row>
                            <b-col cols="12" class="text-center mb-4">
                              <transition name="fade">
                                <div v-if="delivery.status === 'aprobado'">
                                  <p class="font-weight-bold text-primary">¡Listo para ser aceptado!</p>
                                  <b-row class="text-center">
                                    <b-col cols="12" md="12" class="d-flex justify-content-center align-items-center mb-2 mb-md-0 pr-md-1">
                                      <b-button variant="success" size="md" @click="changeStatus('en_transito')" class="w-100">
                                        <feather-icon icon="CheckCircleIcon" class="mr-2" />
                                        Aceptar Pedido
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </div>

                                <div v-else-if="delivery.status === 'en_transito'">
                                  <p class="font-weight-bold text-info">El pedido está en tránsito</p>
                                  <b-row class="text-center">
                                    <b-col cols="12" md="6" class="d-flex justify-content-center align-items-center mb-2 mb-md-0 pr-md-1">
                                      <b-button variant="primary" size="md" @click="changeStatus('entregado')" class="w-100">
                                        <feather-icon icon="CheckIcon" class="mr-2" />
                                        Marcar como Entregado
                                      </b-button>
                                    </b-col>
                                    <b-col cols="12" md="6" class="d-flex justify-content-center align-items-center pl-md-1">
                                      <b-button variant="danger" size="md" @click="changeStatus('no_entregado')" class="w-100">
                                        <feather-icon icon="XIcon" class="mr-2" />
                                        Marcar como No Entregado
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </div>

                                <div v-else-if="delivery.status === 'entregado'">
                                  <p class="font-weight-bold text-success">Pedido entregado con éxito</p>
                                  <feather-icon icon="SmileIcon" size="48" class="text-success" />
                                </div>

                                <div v-else-if="delivery.status === 'no_entregado'">
                                  <p class="font-weight-bold text-danger">Pedido no entregado</p>
                                  <feather-icon icon="FrownIcon" size="48" class="text-danger" />
                                </div>
                              </transition>
                            </b-col>
                          </b-row>
                          <b-row>
                              <b-col cols="12" class="pb-2">
                                  <span>
                                      <b>{{  delivery.status.toUpperCase() + "  el " + delivery.updated_at  }}</b>
                                  </span>
                                  <div class="alert alert-warning mt-1" v-if="delivery.status === 'rechazado' && delivery.rejected_reason">
                                      {{ delivery.rejected_reason  }}
                                  </div>
                                  <div class="alert alert-success mt-1" v-if="delivery.status === 'aprobado' && delivery.fee">
                                      {{ "Cuota moderadora: $ " + delivery.fee }}
                                  </div>
                              </b-col>
                          </b-row>
                          
                          <b-row>
                              <b-col cols="12" md="6">
                                  <b-card class="border" title="Farmacia">
                                      <b-form-group>
                                          <label for=""><b>Farmacia</b></label>
                                          <span class="d-block">{{ delivery.customer.name }}</span>
                                      </b-form-group>
                                      <b-form-group>
                                          <label for=""><b>Télefono</b></label>
                                          <span class="d-block">{{ delivery.customer.phone }}</span>
                                      </b-form-group>
                                      <b-form-group>
                                          <label for=""><b>Celular</b></label>
                                          <span class="d-block">{{ delivery.customer.mobile }}</span>
                                      </b-form-group>
                                      <b-form-group>
                                          <label for=""><b>Correo</b></label>
                                          <span class="d-block">{{ delivery.customer.email }}</span>
                                      </b-form-group>
                                  </b-card> 
                              </b-col>
                              <b-col cols="12" md="6">
                                  <b-card class="border" title="Solicitante">
                                      <b-form-group>
                                          <label for=""><b>Nombre</b></label>
                                          <span class="d-block">{{ delivery.person.names }}</span>
                                      </b-form-group>
                                      <b-form-group>
                                          <label for=""><b>Apellido</b></label>
                                          <span class="d-block">{{ delivery.person.surnames }}</span>
                                      </b-form-group>
                                      <b-form-group>
                                          <label for=""><b>Teléfono</b></label>
                                          <span class="d-block">{{ delivery.person.phone }}</span>
                                      </b-form-group>
                                      <b-form-group>
                                          <label for=""><b>Correo</b></label>
                                          <span class="d-block">{{ delivery.person.email }}</span>
                                      </b-form-group>
                                  </b-card>                             
                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col cols="12" md="6">
                                  <b-card class="border" title="Detalle de la entrega">
                                      <b-row>
                                          <b-col cols="12" md="6">
                                              <b-form-group>
                                                  <label for=""><b>Fecha de creación</b></label>
                                                  <span class="d-block">{{ delivery.created_at }}</span>
                                              </b-form-group>
                                              <b-form-group>
                                                  <label for=""><b>Fecha actualización</b></label>
                                                  <span class="d-block">{{ delivery.updated_at }}</span>
                                              </b-form-group>
                                              <b-form-group>
                                                  <label for=""><b>Precio</b></label>
                                                  <span class="d-block">$ {{ delivery.price }}</span>
                                              </b-form-group>
                                              <b-form-group>
                                                  <label for=""><b>Estado</b></label>
                                                  <span class="d-block">{{ delivery.status.toUpperCase() }}</span>
                                              </b-form-group>
                                              <b-form-group>
                                                  <label for=""><b>Dirección</b></label>
                                                  <span class="d-block">{{ delivery.address }}</span>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                  </b-card>
                              </b-col>
                              <b-col cols="12" md="6">
                                  <b-card class="border" title="Detalle de la entrega">
                                      <b-row>
                                          <b-col cols="12">
                                              <b-table 
                                                  class="position-relative"
                                                  responsive
                                                  :per-page="perPage"
                                                  :current-page="currentPage" :items="itemsProducts"
                                                  :fields="fieldsProducts" :sort-by.sync="sortBy"
                                                  :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                                                  :filter="filter" :filter-included-fields="filterOn">
                                              </b-table>
                                              <b-col cols="12">
                                                  <b-pagination v-model="currentPage" :total-rows="totalRows"
                                                      :per-page="perPage" align="center" size="sm" class="my-0" />
                                              </b-col>
                                              <p><b>Formulas médicas</b></p>
                                              <b-table class="mt-1" responsive :items="delivery.orderFiles"
                                                  :fields="fields">
                                                  <template #cell(type)="data">
                                                      {{ getTypeFile(data) }}
                                                  </template>
                                                  <template #cell(actions)="data">
                                                      <b-button variant="gradient-primary" class="btn-icon"
                                                          @click="openModalFile(data.item)">
                                                          <feather-icon icon="EyeIcon" />
                                                      </b-button>
                                                      <b-button variant="gradient-primary" class="btn-icon"
                                                          @click="downloadFile(data.item)">
                                                          <feather-icon icon="DownloadIcon" />
                                                      </b-button>
                                                  </template>
                                              </b-table>
                                              <p><b>Autorizaciones</b></p>
                                              <b-table class="mt-1" responsive :items="delivery.licenseFiles"
                                                  :fields="fields">
                                                  <template #cell(type)="data">
                                                      {{ getTypeFile(data) }}
                                                  </template>
                                                  <template #cell(actions)="data">
                                                      <b-button variant="gradient-primary" class="btn-icon"
                                                          @click="openModalFile(data.item)">
                                                          <feather-icon icon="EyeIcon" />
                                                      </b-button>
                                                      <b-button variant="gradient-primary" class="btn-icon"
                                                          @click="downloadFile(data.item)">
                                                          <feather-icon icon="DownloadIcon" />
                                                      </b-button>
                                                  </template>
                                              </b-table>
                                          </b-col>
                                      </b-row>
                                      <b-row>
                                          <b-col>
                                              <hr>
                                              <b-form-group>
                                                  <label for=""><b>Código entrega</b></label>
                                                  <span class="d-block">{{ id }}</span>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                  </b-card>
                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col cols="12" md="12">
                                  <b-card class="border" title="Seguimiento">
                                      <GmapMap
                                          ref="map"
                                          :center="center"
                                          :zoom="13"
                                          style="width: 100%; height: 600px"
                                          :options="mapOptions"
                                      >
                                          <!-- Marcador de la tienda con ícono personalizado -->
                                          <GmapMarker
                                              v-if="center.lat && center.lng"
                                              :position="center"
                                              :icon="{
                                                  url: 'https://mimedicamento.com.co/img/logo.3a7c8281.svg',
                                                  scaledSize: { width: 32, height: 32 }
                                              }"
                                          />
                                          <!-- Marcador de la ubicación del cliente -->
                                          <GmapMarker
                                              v-if="customerMarker.lat && customerMarker.lng"
                                              :position="customerMarker"
                                          />
                                          <!-- Línea de ruta -->
                                          <GmapPolyline
                                              v-if="directions && directions.routes[0] && directions.routes[0].overview_path"
                                              :path="directions.routes[0].overview_path"
                                              :options="{
                                                  strokeColor: '#FF0000',
                                                  strokeOpacity: 1.0,
                                                  strokeWeight: 4
                                              }"
                                          />
                                      </GmapMap>
                                  </b-card>
                              </b-col>
                          </b-row>
                      </b-card>
                  </b-col>
              </b-row>
          </b-container>
      </b-card>
      <b-modal id="modal-center" v-model="modalFile.status" centered :title="modalFile.data.name" ok-only size="lg" fluid
            ok-title="Cerrar">
            <b-card-text>
                <b-embed v-if="modalFile.data.fileType == 'pdf'" type="iframe" :src="modalFile.data.path" />
                <b-img v-else-if="modalFile.data.fileType == 'image'" :src="modalFile.data.path" fluid />
            </b-card-text>
        </b-modal>
        <b-modal ref="products" centered title="Crear Productos" ok-only size="lg" fluid ok-title="Cerrar">
            <b-card-text>
                <b-row>
                    <b-col sm="12">
                        <b-button variant="gradient-info" class="btn-icon float-right mb-2" @click="openCums()">
                            <feather-icon icon="SearchIcon" /> Buscar Medicamentos
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <b-form-group label="Nombre Medicamento" v-if="edit == true">
                            <b-form-input v-model="form.name" placeholder="Nombre Medicamento" :disabled="edit" />
                        </b-form-group>
                        <b-form-group label="Nombre Medicamento" v-else>
                            <b-form-input v-model="form.name" placeholder="Nombre Medicamento" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <b-form-group label="Cantidad Solicitada">
                            <b-form-input v-model="form.quantitySolitate" placeholder="Cantidad Solicitada" type="number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <b-form-group label="Cantidad Entregada">
                            <b-form-input v-model="form.deliveryQuantity" placeholder="Cantidad Entregada" type="number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" class="text-center">
                        <b-button variant="gradient-success" class="btn-icon" @click="saveDeliveryHasStock()">
                            Guardar
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-modal>
        <cum-search-table ref="cumSearchTable" @searchCums="filterCums" :pharmacy="pharmacy" />
    </div>
</template>

<script>
import CumSearchTable from '../deliveries/CumsSearchTable.vue';
import { BModal, BTable } from 'bootstrap-vue'
import { gmapApi } from 'vue2-google-maps';
export default {
    components: {
        CumSearchTable,
        BModal,
        BTable
    },
    name: 'DomiciliaryDetail',
    data() {
        return {
            center: { lat: 4.5860706, lng: -74.1041281 },
            customerMarker: { lat: 37.7749, lng: -122.4194 },
            directions: null,
            mapOptions: {
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false,
            },
            form: {
                id: null,
                name: null,
                quantitySolitate: null,
                deliveryQuantity: null
            },
            id: this.$route.params.id,
            delivery: {
                customer: {
                    name: '',
                    phone: '',
                    mobile: '',
                    email: '',
                },
                person: {
                    names: '',
                    surnames: '',
                    phone: '',
                    email: '',
                },
                status: '',
                orderFiles: [],
                licenseFiles: [],
                rejected_reason: '',
            },
            pharmacy: null,
            fields: [
                { key: 'type', label: 'Tipo' },
                { key: 'name', label: 'Archivo' },
                { key: 'number', label: 'Número' },
                { key: 'actions', label: 'Acciones' },
            ],
            modalFile: {
                status: false,
                data: {
                    name: null,
                    path: null,
                    fileType: null,
                },
            },
            perPage: 5,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fieldsProducts: [
                { key: 'cum', label: 'Producto' },
                { key: 'amount', label: 'Cantidad Solicitada', sortable: true },
                { key: 'delivered_quantity', label: 'Cantidad Entregada', sortable: true },
            ],
            itemsProducts: [],
            stock: {},
            edit: false
        }
    },
    computed: {
      google() {
        return gmapApi() || null;
      }
    },
    mounted() {
      if (this.google && this.google.maps) {
        console.log('maps ok')
        this.getRoute(); // Llama a getRoute al montar el componente
      } else {
        this.$watch(
          () => this.google,
          (google) => {
            if (google && google.maps) {
              console.log('maps ok')
              this.getRoute(); // Llama a getRoute cuando la API esté lista
            }
          }
        );
      }
    },
    methods: {
        async getRoute() {
            if (this.google && this.google.maps) {
                const directionsService = new this.google.maps.DirectionsService();
                const request = {
                    origin: this.center,
                    destination: this.customerMarker,
                    travelMode: this.google.maps.TravelMode.DRIVING,
                    drivingOptions: {
                        departureTime: new Date(Date.now()),  // para obtener el tiempo estimado actual.
                        trafficModel: 'pessimistic'
                    }
                };
                directionsService.route(request, (result, status) => {
                    if (status === 'OK') {
                        this.directions = result;
                        this.$refs.map.panToBounds(result.routes[0].bounds);
                    } else {
                        console.error(`Error fetching directions: ${status}`);
                    }
                });
            }
        },
        async deleteProduct(id) {
            await this.$http.post('/entities/delete-deliveries-has-stocks/' + id).then((response) => {
                if (response.data.code == 200) {
                    this.$swal({
                        title: response.data.message,
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    });
                    this.getDelivery().then(() => {
                        setTimeout(() => {
                            this.fetchDeliveriesHasStocks(this.delivery.id);
                        }, 2000);
                    });
                }
                if (response.data.code == 500) {
                    this.$swal({
                        title: response.data.message,
                        icon: 'warning',
                        customClass: {
                            confirmButton: 'btn btn-warning',
                        },
                        buttonsStyling: false,
                    })
                }
            }).catch((error) => {
                console.error('Error al enviar la solicitud POST:', error);
            });
        },
        async editProduct(data) {
            this.$refs['products'].show()
            this.edit = true
            this.form = {
                id: data.id,
                name: data.cum,
                quantitySolitate: data.amount,
                deliveryQuantity: data.delivered_quantity
            }
        },
        async fetchDeliveriesHasStocks(id) {
            const payload = {
                stock: this.stock
            };
            await this.$http.post('/entities/list-deliveries-has-stocks/' + id, payload).then((response) => {
                this.itemsProducts = response.data.deliveries;
            }).catch((error) => {
                console.error('Error al enviar la solicitud POST:', error);
            });
        },
        async saveDeliveryHasStock() {
            if (this.form.deliveryQuantity > this.stock.quantity) {
                this.$swal({
                    title: 'La cantidad especificada para la entrega supera el stock disponible del medicamento seleccionado. No es posible procesar la entrega con la cantidad ingresada. Por favor, verifique el inventario actual y actualice las cantidades disponibles o reduzca la cantidad especificada para la entrega.',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                    buttonsStyling: false,
                });
                return false
            }
            if (this.edit == true) {
                await this.$http.post('/entities/edit-deliveries-has-stocks/' + this.form.id + '/' + this.delivery.id, this.form).then((response) => {
                    if (response.data.code == 200) {
                        this.$swal({
                            title: response.data.message,
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        });
                        this.$refs['products'].hide()
                        this.form = {
                            id: null,
                            name: null,
                            quantitySolitate: null,
                            deliveryQuantity: null
                        }
                        this.stock = response.data.stock
                        this.fetchDeliveriesHasStocks(this.delivery.id)
                    }
                    if (response.data.code == 500) {
                        this.$swal({
                            title: response.data.message,
                            icon: 'warning',
                            customClass: {
                                confirmButton: 'btn btn-warning',
                            },
                            buttonsStyling: false,
                        })
                    }
                }).catch((error) => {
                    console.error('Error al enviar la solicitud POST:', error);
                });
            } else {

                this.$http.post('/entities/store/' + this.delivery.id, this.form)
                    .then(response => {
                        if (response.data.code == 200) {
                            this.$swal({
                                title: response.data.message,
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                                buttonsStyling: false,
                            });
                            this.$refs['products'].hide()
                            this.form = {
                                id: null,
                                name: null,
                                quantitySolitate: null,
                                deliveryQuantity: null
                            }
                            this.stock = response.data.stock
                            this.fetchDeliveriesHasStocks(this.delivery.id)
                        }
                        if (response.data.code == 500) {
                            this.$swal({
                                title: response.data.message,
                                icon: 'warning',
                                customClass: {
                                    confirmButton: 'btn btn-warning',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                    });
            }
        },
        filterCums(item) {
            this.form.id = item.id
            this.form.name = item.producto
            this.assignQuantity()
        },
        async assignQuantity() {
            await this.$http.post('/entities/search-cums-for-pharmacy-id/' + this.form.id, this.pharmacy).then((response) => {
                this.form.quantitySolitate = response.data.delivery.amount
                this.form.deliveryQuantity = response.data.delivery.delivered_quantity
                this.form.alert = response.data.alert
                this.stock = response.data.stock
            })
            this.tableSettings.busy = false;
        },
        showCums() {
            this.form = {
                id: null,
                name: null,
                quantitySolitate: null,
                deliveryQuantity: null
            }
            this.$refs['products'].show()
        },
        openCums() {
            this.pharmacy = this.delivery
            this.$refs.cumSearchTable.showModal();
        },

        async getDelivery() {
            try {
                const { data } = await this.$http.get(`domiciliaries/${this.id}`);
                this.delivery = data;

                if (data.customer && data.customer.location) {
                    this.center = data.customer.location;
                }

                if (data.lat && data.lng) {
                    this.customerMarker = {
                        lat: parseFloat(data.lat),
                        lng: parseFloat(data.lng),
                    };
                }

                // Llama a getRoute después de obtener los datos de entrega
                this.getRoute();

            } catch (error) {
                console.error('Error fetching delivery:', error);
            }
        },

        async changeStatus(status) {
            try {
                this.$swal({
                    title: `¿Está seguro de que quieres marcar como ${status}?`,
                    text: "Podrá revertir esta acción más tarde.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async result => {
                    if (result.value) {
                        if (status === 'aprobado') {
                            const { value: fee } = await this.$swal({
                                title: 'Ingrese la cuota moderadora',
                                input: 'text',
                                inputLabel: 'Cuota moderadora',
                                inputPlaceholder: '$ 0',
                                showCancelButton: true,
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Aceptar',
                                inputValidator: (value) => {
                                    // Validar que el valor sea numérico
                                    if (!value) {
                                        return 'Debe ingresar un valor';
                                    } else if (!/^[0-9]+([.][0-9]+)?$/.test(value)) {
                                        return 'Debe ingresar un valor numérico';
                                    }
                                }
                            });
                            
                            const { data } = await this.$http.put(`deliveries/${this.id}`, {status, fee});
                            this.delivery = data;
                            this.$swal({
                                title: '¡Listo!',
                                text: 'Se ha actualizado el estado de la entrega',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                                buttonsStyling: false,
                            });
                        } else if (status === 'rechazado') {

                            const { value: rejected_reason } = await this.$swal({
                                title: 'Ingrese el motivo del rechazo',
                                input: 'textarea',
                                inputLabel: 'Motivo de rechazo',
                                inputPlaceholder: 'Motivo de rechazo',
                                showCancelButton: true,
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Aceptar',
                                inputValidator: (value) => {
                                    if (!value) {
                                        return 'Debe ingresar al menos un motivo';
                                    }
                                }
                            });

                            const { data } = await this.$http.put(`deliveries/${this.id}`, {status, rejected_reason});
                            this.delivery = data;
                            this.$swal({
                                title: '¡Listo!',
                                text: 'Se ha actualizado el estado de la entrega',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                                buttonsStyling: false,
                            });
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },

        getTypeFile(data) {
            const type = data.item.type === 'order' ? 'Orden médica' : data.item.type === 'license' ? 'Autorización' : '';
            return type;
        },
        openModalFile(data) {
            this.modalFile.status = true;
            this.modalFile.data = data;
        },
        modalFileReset() {
            this.modalFile = {
                status: false,
                data: {
                    name: null,
                    path: null,
                    fileType: null,
                },
            }
        },
        downloadFile(data) {
            window.open(data.path, '_blank');
        },
        handleRowClicked(itemId) {
            alert(itemId);
            console.log('Item clicado en otra vista:', itemId);
        },
    },
    created() {
        this.getDelivery().then(() => {
            setTimeout(() => {
                this.fetchDeliveriesHasStocks(this.delivery.id);
            }, 2000);
        });
        CumSearchTable.$on('row-clicked', this.handleRowClicked);
    },
    destroyed() {
        CumSearchTable.$off('row-clicked', this.handleRowClicked);
    },
}
</script>